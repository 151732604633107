import z from "zod";

export const envClientSchemaObj = {
  NEXT_PUBLIC_SENTRY_DSN: z.string().url(),
  NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE: z.coerce.number().default(1),
  NEXT_PUBLIC_SENTRY_REPLAY_ERROR_SAMPLE_RATE: z.coerce.number().default(0.5),
  NEXT_PUBLIC_API_BASE_URL: z.string().url(),
  NEXT_PUBLIC_BASE_URL: z.string().url(),
  NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.string().default("development"),

  // Feature Flag
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_OI: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_EXTERNAL: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_INTERNAL: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_CONFIG: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_MASTER_DATA: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_SUPPLIER_PAGE: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_IAM: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_TAX: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_RECEIPT_INVOICE: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_TWM: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_OTC: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_PAYMENT: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_DASHBOARD: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_OPEX: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_CAPEX: z
    .enum(["true", "false"])
    .transform((value) => value === "true"),
  NEXT_PUBLIC_FEATURE_FLAG_FILLING_SYSTEM: z
    .enum(["true", "false"])
    .optional() // tempororary
    .default("false") // tempororary
    .transform((value) => value === "true"),
};
export const envClientCollectionObj = {
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE:
    process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE,
  NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
  NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,

  // Feature Flag
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_OI:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_INVOICE_OI,
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_INTERNAL:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_INTERNAL,
  NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_EXTERNAL:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_INVOICE_NOI_EXTERNAL,
  NEXT_PUBLIC_FEATURE_FLAG_CONFIG: process.env.NEXT_PUBLIC_FEATURE_FLAG_CONFIG,
  NEXT_PUBLIC_FEATURE_FLAG_MASTER_DATA:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_MASTER_DATA,
  NEXT_PUBLIC_FEATURE_FLAG_SUPPLIER_PAGE:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SUPPLIER_PAGE,
  NEXT_PUBLIC_FEATURE_FLAG_IAM: process.env.NEXT_PUBLIC_FEATURE_FLAG_IAM,
  NEXT_PUBLIC_FEATURE_FLAG_TAX: process.env.NEXT_PUBLIC_FEATURE_FLAG_TAX,
  NEXT_PUBLIC_FEATURE_FLAG_RECEIPT_INVOICE:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_RECEIPT_INVOICE,
  NEXT_PUBLIC_FEATURE_FLAG_TWM: process.env.NEXT_PUBLIC_FEATURE_FLAG_TWM,
  NEXT_PUBLIC_FEATURE_FLAG_OTC: process.env.NEXT_PUBLIC_FEATURE_FLAG_OTC,
  NEXT_PUBLIC_FEATURE_FLAG_PAYMENT:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_PAYMENT,
  NEXT_PUBLIC_FEATURE_FLAG_DASHBOARD:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DASHBOARD,
  NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_OPEX:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_OPEX,
  NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_CAPEX:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_BUDGET_PLAN_CAPEX,
  NEXT_PUBLIC_FEATURE_FLAG_FILLING_SYSTEM:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_FILLING_SYSTEM ?? false, //temporary
};

export const envClientSchema = z.object(envClientSchemaObj);
export const envClient = envClientSchema.parse(envClientCollectionObj);
